// Global Search Modal

.modal-global-search {
  transform: none;

  &.modal.fade {
    .modal-dialog {
      transform: none;
    }
  }

  .modal-expand {
    @each $breakpoint in map-keys($grid-breakpoints) {
      $next: breakpoint-next($breakpoint, $grid-breakpoints);
      $infix: breakpoint-infix($next, $grid-breakpoints);

      &#{$infix} {
        @include media-breakpoint-down($breakpoint) {
          max-width: none;
        }

        @include media-breakpoint-up($next) {
          .modal-header {
            &.navbar {
              /* stylelint-disable-next-line selector-max-class */
              .form-control {
                color: $input-icon-color;
                background-color: transparent;
                border: none;
                box-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  .modal-dialog {
    margin-top: 0;

    > .container {
      padding: 0;
    }
  }

  .modal-content {
    background-color: transparent;
    border: 0;
  }

  .modal-header {
    &.navbar {
      padding: $navbar-padding-y 0;
      background: transparent;

      &.navbar-dark {
        /* stylelint-disable-next-line selector-max-class */
        .form-control {
          color: $navbar-dark-color;
        }
      }

      &.navbar-light {
        /* stylelint-disable-next-line selector-max-class */
        .form-control {
          color: $navbar-light-color;
        }
      }

      .form-control {
        background: none;
        border: none;

        &:focus {
          box-shadow: none;
        }
      }
    }
  }

  .modal-body {
    background-color: $white;
    border-bottom-right-radius: $modal-content-border-radius;
    border-bottom-left-radius: $modal-content-border-radius;
  }
}
