// Directory Footer

.footer-directory {
  a {
    color: inherit;
  }

  h3 {
    font-size: inherit;
  }

  @include media-breakpoint-down(xs) {
    a {
      padding-left: $spacer;
    }

    h3 {
      padding-top: $spacer;
      padding-bottom: $spacer;
      margin-bottom: 0;
    }

    ul {
      margin-bottom: 0;

      li:last-child {
        margin-bottom: $spacer;
      }
    }

    .section {
      border-bottom: solid $border-width $border-color;
    }
  }
}
