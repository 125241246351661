@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 100;
  src: url("#{$font-path-corals}corals-thin.eot");
  src: url("#{$font-path-corals}corals-thin.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-thin.woff2") format("woff2"), url("#{$font-path-corals}corals-thin.woff") format("woff"), url("#{$font-path-corals}corals-thin.ttf") format("truetype"), url("#{$font-path-corals}corals-thin.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 100;
  src: url("#{$font-path-corals}corals-thin-italic.eot");
  src: url("#{$font-path-corals}corals-thin-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-thin-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-thin-italic.woff") format("woff"), url("#{$font-path-corals}corals-thin-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-thin-italic.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 300;
  src: url("#{$font-path-corals}corals-light.eot");
  src: url("#{$font-path-corals}corals-light.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-light.woff2") format("woff2"), url("#{$font-path-corals}corals-light.woff") format("woff"), url("#{$font-path-corals}corals-light.ttf") format("truetype"), url("#{$font-path-corals}corals-light.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 300;
  src: url("#{$font-path-corals}corals-light-italic.eot");
  src: url("#{$font-path-corals}corals-light-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-light-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-light-italic.woff") format("woff"), url("#{$font-path-corals}corals-light-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-light-italic.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 400;
  src: url("#{$font-path-corals}corals-regular.eot");
  src: url("#{$font-path-corals}corals-regular.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-regular.woff2") format("woff2"), url("#{$font-path-corals}corals-regular.woff") format("woff"), url("#{$font-path-corals}corals-regular.ttf") format("truetype"), url("#{$font-path-corals}corals-regular.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 400;
  src: url("#{$font-path-corals}corals-italic.eot");
  src: url("#{$font-path-corals}corals-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-italic.woff") format("woff"), url("#{$font-path-corals}corals-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-italic.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 700;
  src: url("#{$font-path-corals}corals-bold.eot");
  src: url("#{$font-path-corals}corals-bold.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-bold.woff2") format("woff2"), url("#{$font-path-corals}corals-bold.woff") format("woff"), url("#{$font-path-corals}corals-bold.ttf") format("truetype"), url("#{$font-path-corals}corals-bold.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 700;
  src: url("#{$font-path-corals}corals-bold-italic.eot");
  src: url("#{$font-path-corals}corals-bold-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-bold-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-bold-italic.woff") format("woff"), url("#{$font-path-corals}corals-bold-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-bold-italic.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 800;
  src: url("#{$font-path-corals}corals-extra-bold.eot");
  src: url("#{$font-path-corals}corals-extra-bold.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-extra-bold.woff2") format("woff2"), url("#{$font-path-corals}corals-extra-bold.woff") format("woff"), url("#{$font-path-corals}corals-extra-bold.ttf") format("truetype"), url("#{$font-path-corals}corals-extra-bold.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 800;
  src: url("#{$font-path-corals}corals-extra-bold-italic.eot");
  src: url("#{$font-path-corals}corals-extra-bold-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-extra-bold-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-extra-bold-italic.woff") format("woff"), url("#{$font-path-corals}corals-extra-bold-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-extra-bold-italic.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: normal;
  font-weight: 900;
  src: url("#{$font-path-corals}corals-black.eot");
  src: url("#{$font-path-corals}corals-black.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-black.woff2") format("woff2"), url("#{$font-path-corals}corals-black.woff") format("woff"), url("#{$font-path-corals}corals-black.ttf") format("truetype"), url("#{$font-path-corals}corals-black.svg#wf") format("svg");
  font-display: swap;
}

@font-face {
  font-family: "Cozy Glow Corals";
  font-style: italic;
  font-weight: 900;
  src: url("#{$font-path-corals}corals-black-italic.eot");
  src: url("#{$font-path-corals}corals-black-italic.eot?#iefix") format("eot"), url("#{$font-path-corals}corals-black-italic.woff2") format("woff2"), url("#{$font-path-corals}corals-black-italic.woff") format("woff"), url("#{$font-path-corals}corals-black-italic.ttf") format("truetype"), url("#{$font-path-corals}corals-black-italic.svg#wf") format("svg");
  font-display: swap;
}
